exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-initiatives-tsx": () => import("./../../../src/pages/initiatives.tsx" /* webpackChunkName: "component---src-pages-initiatives-tsx" */),
  "component---src-pages-news-tsx": () => import("./../../../src/pages/news.tsx" /* webpackChunkName: "component---src-pages-news-tsx" */),
  "component---src-pages-sections-about-main-content-tsx": () => import("./../../../src/pages/sections/AboutMainContent.tsx" /* webpackChunkName: "component---src-pages-sections-about-main-content-tsx" */),
  "component---src-pages-sections-about-where-tsx": () => import("./../../../src/pages/sections/AboutWhere.tsx" /* webpackChunkName: "component---src-pages-sections-about-where-tsx" */),
  "component---src-pages-sections-aside-donate-tsx": () => import("./../../../src/pages/sections/asideDonate.tsx" /* webpackChunkName: "component---src-pages-sections-aside-donate-tsx" */),
  "component---src-pages-sections-call-to-action-tsx": () => import("./../../../src/pages/sections/callToAction.tsx" /* webpackChunkName: "component---src-pages-sections-call-to-action-tsx" */),
  "component---src-pages-sections-main-content-tsx": () => import("./../../../src/pages/sections/MainContent.tsx" /* webpackChunkName: "component---src-pages-sections-main-content-tsx" */),
  "component---src-pages-sections-news-hero-tsx": () => import("./../../../src/pages/sections/NewsHero.tsx" /* webpackChunkName: "component---src-pages-sections-news-hero-tsx" */),
  "component---src-pages-sections-programs-tsx": () => import("./../../../src/pages/sections/Programs.tsx" /* webpackChunkName: "component---src-pages-sections-programs-tsx" */),
  "component---src-pages-sections-services-tsx": () => import("./../../../src/pages/sections/services.tsx" /* webpackChunkName: "component---src-pages-sections-services-tsx" */),
  "component---src-pages-sections-teams-tsx": () => import("./../../../src/pages/sections/teams.tsx" /* webpackChunkName: "component---src-pages-sections-teams-tsx" */),
  "component---src-pages-sections-testimonials-tsx": () => import("./../../../src/pages/sections/Testimonials.tsx" /* webpackChunkName: "component---src-pages-sections-testimonials-tsx" */),
  "component---src-pages-sections-who-we-serve-tsx": () => import("./../../../src/pages/sections/whoWeServe.tsx" /* webpackChunkName: "component---src-pages-sections-who-we-serve-tsx" */)
}

